import {Form, Input, Switch} from 'antd';

const MemberStatusForm = ({form, onFinish}) => {
    return (
        <Form
            form={form}
            name="MemberStatusForm"
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            layout="horizontal"
            onFinish={onFinish}
        >
            <Form.Item label="Statusname" name="name">
                <Input/>
            </Form.Item>
            <Form.Item label="Ordnung" name="order">
                <Input/>
            </Form.Item>
            <Form.Item label="Ist aktiv" valuePropName="checked" name="is_visible" initialValue={true}>
                <Switch/>
            </Form.Item>
        </Form>
    );
};

export default MemberStatusForm;
