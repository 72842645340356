import {DownloadOutlined} from '@ant-design/icons';
import {Modal} from 'antd';
import api from '../../api';
import CRUD from '../../components/CRUD';
import {formatDate} from '../../utils/normalize';
import MeetingConfirmationTemplateForm from '../MeetingControl/MeetingConfirmationTemplateForm';
import ConfirmationCreateForm from './ConfirmationCreateForm';
import ConfirmationForm from './ConfirmationForm';
import ConfirmationPDF from './ConfirmationPDF';
import ConfirmationSendingForm from './ConfirmationSendingForm';
import ConfirmationSendingOneForm from './ConfirmationSendingOneForm';
import ConfirmationDeleteAllForm from './ConfirmationDeleteAllForm';

const ConfirmationList = ({searchParams}) => {

const getAllPDF = e => {
  api.confirmation.confirmationAllPDF({
    filename: `confirmations-${searchParams.meeting}.pdf`,
    params: {
      meetingId: searchParams.meeting,
    }
  });
}

function info(row) {
  Modal.info({
    title: 'Error',
    content: (
      <div>
        <p>{row.error}</p>
      </div>
    ),
    onOk() {},
  });
}

  const columns = [
    {
      title: 'Frau/Herr',
      dataIndex: 'greeting',
      key: 'greeting',
    },
    {
      title: 'Nachname',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
    },
    {
      title: 'Vorname',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Teilnehmerstatus',
      dataIndex: 'status_name',
      key: 'status_name',
      sorter: (a, b) => a.status_name.localeCompare(b.status_name),
    },
    {
      title: 'Versandstatus',
      dataIndex: 'is_success',
      key: 'is_success',
      render: ((text, row) => {
        if (text === false || text === true) {
          return text ? 'Erfolg' : <a onClick={()=>info(row)}>Error</a>;
        }
      }),
      sorter: (a, b) => a - b,
    },
    {
      title: 'Versanddatum',
      dataIndex: 'date_sent',
      key: 'date_sent',
      render: (text => {
        return text ? formatDate(text, 'YYYY-MM-DD HH:mm:ss', 'DD.MM.YYYY HH:mm:ss') : ''
      }),
      sorter: (a, b) => a.date_sent > b.date_sent,
    },
  ];
  return <>
    <CRUD
    keyName="confirmation"
    title="Teilnehmerbestätigung"
    FormComponent={ConfirmationForm}
    tableColumns={columns}
    searchParams={searchParams}
    itemValueName="last_name"
    apiList={api.confirmation.confirmationList}
    apiRetrieve={api.confirmation.confirmationRetrieve}
    apiDelete={api.confirmation.confirmationDelete}
    listRefetchInterval={10000}
    extraFields={{meeting: searchParams['meeting']}}
    childrenActions={{'DownloadPDF': {
        childrenActionName: 'Herunterladen PDF',
        ChildrenCRUD: ConfirmationPDF,
      },
      'Send': {
        childrenActionName: 'Erneut senden',
        ChildrenCRUD: ConfirmationSendingOneForm,
      },
    }}
    commonActions={{
      'generate': {
        ActionComponent: ConfirmationCreateForm,
        buttonLabel: 'Teilnehmerbestätigung erstellen',
        extraProps: {
          parentId: searchParams['meeting'],
        },
        width: 900,
      },
      'delete': {
        ActionComponent: ConfirmationDeleteAllForm,
        buttonLabel: 'Alles löschen',
        extraProps: {
          parentId: searchParams['meeting'],
        },
        width: 900,
      },
      'template': {
        ActionComponent: MeetingConfirmationTemplateForm,
        buttonLabel: 'Teilnehmerbestätigungsvorlage',
        buttonType: 'secondary',
        extraProps: {
          parentId: searchParams['meeting'],
        },
        width: 900,
      },
      'sending': {
        ActionComponent: ConfirmationSendingForm,
        buttonLabel: 'E-Mail schicken',
        buttonType: 'secondary',
        extraProps: {
          meeting: searchParams['meeting'],
        },
        width: 700,
      },
      'report': {
        buttonLabel: 'PDF mit allen Teilnehmerbestätigung herunterladen',
        buttonType: 'secondary',
        buttonExtraProps: {
          icon: <DownloadOutlined/>,
          onClick: getAllPDF,
          shape: "round"
        },
        width: 700,
      },
    }}
  /></>
};

export default ConfirmationList;
