import {
    DatabaseOutlined,
    MailOutlined,
    TeamOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';

export const menuLinks = [
    {name: 'Tagungen verwalten', url: '/meetings-control', icon: <DatabaseOutlined/>},
    {name: 'Tagungen', url: '/', icon: <TeamOutlined/>},
    {name: 'Einladungen', url: '/invitation', icon: <MailOutlined />},
    {name: 'Rechnungen', url: '/invoice', icon: <MailOutlined />},
    {name: 'Teilnehmerbestätigung', url: '/confirmation', icon: <MailOutlined />},
    {
        name: 'Listen', icon: <UnorderedListOutlined />,
        subItems: [
            {name: 'Teilnehmerstatus', url: '/member_statuses'},
            {name: 'Bundesland', url: '/states'},
        ],
    },
    {name: 'Datenexport', url: '/reports', icon: <DatabaseOutlined/>},
];
