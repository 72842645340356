import api from '../../api';
import {Button} from 'antd';
import {useMutation, useQueryClient} from 'react-query';

const ConfirmationDeleteAllForm = ({parentId, setVisible, itemData}) => {
  const queryClient = useQueryClient();
  
  const {mutateAsync: mutateAsyncUpdate} = useMutation(
    api.confirmation.confirmationDeleteAll, {
      onSuccess: async () => {
        setVisible(false);
        await queryClient.invalidateQueries('confirmation-list');
      }
    });

  const startDelete = async () => {
    await mutateAsyncUpdate({meetingId: parentId});
  }

  return <div><h3 className="title">Alles löschen</h3>
  <hr className="hr"/>
  <Button style={{marginBottom: '20px'}} onClick={startDelete}>
  Alles löschen
</Button>
</div>
}

export default ConfirmationDeleteAllForm;